import "./you.scss"

import React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"

// import Banner from "../components/Banner"
// import BannerCall from "../components/BannerCall"
// import Choices from "../components/for-you/Choices"
// import Shelf from "../components/Shelf"
// import Slogan from "../components/Slogan"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
  getGatsbyImage,
} from "../plugins/common"

const Banner = loadable(() => import("../components/Banner"))
const BannerCall = loadable(() => import("../components/BannerCall"))
const Choices = loadable(() => import("../components/for-you/Choices"))
const Shelf = loadable(() => import("../components/Shelf"))
const Slogan = loadable(() => import("../components/Slogan"))

const ForYouPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext
  const aleaToken = general.aleaToken
  // const data = pageContext.node
  const empowerDashColor = "bg-copper"
  const headerShapes = "../images/for-you/for-you-header-shape.webp"
  const bannerEmptyShapes = "../images/for-you/banner-empty.webp"
  const bannerFullShapes = "../images/for-you/banner-full.webp"

  const shelfLeft = "../images/for-you/for-you-shelf-left.webp"
  const shelfRight = "../images/for-you/for-you-shelf-right.webp"
  const shelfLeftBottom = "../images/for-you/forYouShelfBottom.webp"
  const shelfBottomRight = "../images/for-you/shelfBottomRight.webp"

  const headerImage = getGatsbyImage(data.headerImage.image[0])
  const bannerImage = getGatsbyImage(data.banner.image[0])
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerImage.image[0])

  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  return (
    <>
      <Layout
        props={pageContext.general}
        meta={data.meta}
        localizations={getAllLocales(data.localizations, data.locale)}
        location={location}
        lang={lang}
        ogImage={ogImage}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={enableFooterForm ? footerForm : null}
      >
        <div className="overflow-hidden">
          <Slogan props={data.headerImage} lang={lang}>
            <div id="right" className="youHeader position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="shapes position-absolute"
                src={headerShapes}
                alt=""
                layout="constrained"
              />
              <div className="imageZoom soft-corners d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni position-absolute"
                  image={headerImage}
                  alt="alea"
                />
              </div>
            </div>
          </Slogan>

          <div className="youChoices">
            <Choices
              props={data.products}
              lang={lang}
              general={pageContext.general}
            />
          </div>

          <div className="youBanner position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="shapesEmpty position-absolute"
              src={bannerEmptyShapes}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="shapesFull position-absolute"
              src={bannerFullShapes}
              alt=""
              layout="constrained"
            />
            <div className="imageZoom soft-corners d-unset">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="image imageAni position-absolute"
                image={bannerImage}
                alt="alea"
              />
            </div>
            <BannerCall props={data.banner} bgColor="bg-daffodil" lang={lang} />
          </div>

          <div className="youShelf position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="shelfLeft position-absolute"
              src={shelfLeft}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="shelfRight position-absolute"
              src={shelfRight}
              alt=""
              layout="constrained"
            />

            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="shelfLeftBottom position-absolute"
              src={shelfLeftBottom}
              alt=""
              layout="constrained"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="shelfBottomRight position-absolute"
              src={shelfBottomRight}
              alt=""
              layout="constrained"
            />
            <Shelf
              title={data.uniqueSellingPoints.title}
              list={data.uniqueSellingPoints.uspList}
              startFrom="left"
              dashColor={empowerDashColor}
              lang={lang}
            />
          </div>
          <Banner props={data.banner2} lang={lang} />
        </div>
      </Layout>
    </>
  )
}
export default ForYouPage
